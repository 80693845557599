import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';

function Payments(props) {
  const { data: { month } } = props;
  const [month2, setMonth] = useState(month);
  const [totals, setTotals] = useState({});
  const [Serror, setSError] = useState({ error: false, message: '' });
  const [Sstatus, setSStatus] = useState();
  const [Perror, setPError] = useState({ error: false, message: '' });
  const [Pstatus, setPStatus] = useState();
  const [invoice, setInvoice] = useState();
  const [professors, setprofessors] = useState([]);
  const [students, setStudents] = useState([]);
  const [studentDisplay, showStudentDisplay] = useState(false);
  const [showStudentFilter, setShowStudentFilter] = useState(true);
  const [student, setStudent] = useState({});
  const [professorDisplay, showProfessorDisplay] = useState(false);
  const [professor, setProfessor] = useState({});
  const [studentFiltering, setStudentFiltering] = useState('name');
  const professorsPath = `/payments/professors?month=${month}`;
  const studentsPath = `/payments/students?month=${month}`;
  const totalsPath = `/payments/totals?month=${month}`;
  const changeStudentStatusPath = '/payments/students/post';
  const changeProfessorStatusPath = '/payments/professors/post';
  const [amountLeft, setAmountLeft] = useState('$0');
  const [professorLeft, setProfessorLeft] = useState(0);
  const [studentAmountLeft, setStudentAmountLeft] = useState('$0');
  const [studentLeft, setStudentLeft] = useState(0);
  const [studentString, setStudentString] = useState({});

  let onProfessorFilter = null;

  let onStudentNameFilter = null;
  let onStudentMotherFilter = null;
  let onStudentAmountFilter = null;

  function getStudentsCharges() {
    fetch(studentsPath)
      .then((response) => response.json())
      .then((data) => {
        setStudents(data.amountList);
        setStudentAmountLeft(data.amountLeft);
        setStudentLeft(data.studentLeft);
        setStudentString(data.studentString);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getProfessorsPays() {
    fetch(professorsPath)
      .then((response) => response.json())
      .then((data) => {
        setprofessors(data.amountList);
        setAmountLeft(data.amountLeft);
        setProfessorLeft(data.professorLeft);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    fetch(totalsPath)
      .then((response) => response.json())
      .then((data) => {
        setTotals(data);
      })
      .then(() => {
        getProfessorsPays();
        getStudentsCharges();
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  function professorStatusFormatter(cell, row) {
    if (!row.pays[0] || row.pays[0].status === '') {
      return <input type="checkbox" onClick={(event) => professorStatusChange(event, row)} />;
    } if (row.pays[0].status === 'OK') {
      return <input type="checkbox" defaultChecked onClick={(event) => professorStatusChange(event, row)} />;
    }
    return <p>No registra</p>;
  }

  function professorStatusChange(event, row) {
    event.persist();
    fetch(changeProfessorStatusPath, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ProfessorId: row.id,
        month: month2,
        status: event.target.checked ? 'OK' : '',
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          setprofessors((prevProfessors) => prevProfessors.map((prof) => {
            if (prof.id === row.id) {
              let newPay;
              if (prof.pays.length === 0) {
                newPay = { status: event.target.checked ? 'OK' : '' };
              } else {
                // eslint-disable-next-line prefer-destructuring
                newPay = prof.pays[0];
                newPay.status = event.target.checked ? 'OK' : '';
              }
              return { ...prof, pays: [newPay] };
            }
            return prof;
          }));
        }
      })
      .catch((error) => {
        console.log(error);
        alert('Hubo un problema al registrar, por favor, intenta denuevo');
      });
  }

  const ProfessorsColumns = [
    {
      filter: textFilter(), dataField: 'name', text: 'Nombre profesor/a', headerStyle: { backgroundColor: '#F69B2F' }, filterRenderer: (onFilter) => { onProfessorFilter = onFilter; return null; },
    },
    {
      dataField: 'totalAmount', text: 'Monto', align: 'center', headerStyle: { backgroundColor: '#F69B2F', width: '23%', textAlign: 'center' },
    },
    {
      dataField: 'pays[0].status', text: 'Estado', formatter: professorStatusFormatter, headerStyle: { backgroundColor: '#F69B2F', width: '20%' },
    },
    {
      dataField: 'admin.shortName', text: 'Emb.', align: 'center', headerStyle: { backgroundColor: '#F69B2F', width: '15%' },
    },
  ];

  function onProfessorChange(event) {
    const { value } = event.target;
    onProfessorFilter(value);
  }

  function studentStatusFormatter(cell, row) {
    if (!row.charge[0] || row.charge[0].status === '') {
      return <input type="checkbox" onClick={(event) => studentStatusChange(event, row)} />;
    } if (row.charge[0].status === 'OK') {
      return <input type="checkbox" defaultChecked onClick={(event) => studentStatusChange(event, row)} />;
    }
    return <p>No registra</p>;
  }

  function studentStatusChange(event, row) {
    event.persist();
    fetch(changeStudentStatusPath, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        StudentId: row.id,
        month: month2,
        status: event.target.checked ? 'OK' : '',
        invoice: row.charge[0].invoice,
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          setStudents((prevStudents) => prevStudents.map((stu) => {
            if (stu.id === row.id) {
              const newCharge = stu.charge[0];
              newCharge.status = event.target.checked ? 'OK' : '';
              return { ...stu, charge: [newCharge] };
            }
            return stu;
          }));
        }
      })
      .catch((error) => {
        console.log(error);
        alert('Hubo un problema al registrar, por favor, intenta denuevo');
      });
  }
  function invoiceFormatter(cell, row) {
    if (!row.charge[0] || row.charge[0].invoice === '') {
      return <p>Pendiente</p>;
    } if (row.charge[0].invoice === 'OK') {
      return <p style={{ color: 'green' }}><strong>Listo</strong></p>;
    }
    return <p>No registra</p>;
  }

  const StudentsColumns = [
    {
      filter: textFilter(), dataField: 'name', text: 'Nombre alumno/a', headerStyle: { backgroundColor: '#F69B2F' }, filterRenderer: (onFilter) => { onStudentNameFilter = onFilter; return null; },
    },
    {
      filter: textFilter(), dataField: 'mother', text: 'Apoderado/a', headerStyle: { backgroundColor: '#F69B2F' }, filterRenderer: (onFilter) => { onStudentMotherFilter = onFilter; return null; },
    },
    {
      filter: textFilter(), dataField: 'totalAmount', text: 'Monto', align: 'center', headerStyle: { backgroundColor: '#F69B2F', width: '14%', textAlign: 'center' }, filterRenderer: (onFilter) => { onStudentAmountFilter = onFilter; return null; },
    },
    {
      dataField: 'charge[0].status', text: 'Estado', formatter: studentStatusFormatter, headerStyle: { backgroundColor: '#F69B2F', width: '15%' },
    },
    {
      dataField: 'charge[0].invoice', text: 'Boleta', formatter: invoiceFormatter, headerStyle: { backgroundColor: '#F69B2F', width: '15%' },
    },
    {
      dataField: 'admin.shortName', text: 'Emb.', align: 'center', headerStyle: { backgroundColor: '#F69B2F', width: '8%' },
    },
  ];

  function onStudentNameChange(event) {
    const { value } = event.target;
    onStudentNameFilter(value);
  }

  function onStudentMotherChange(event) {
    const { value } = event.target;
    onStudentMotherFilter(value);
  }

  function onStudentAmountChange(event) {
    const { value } = event.target;
    onStudentAmountFilter(value);
  }

  const studentRowEvent = {
    style: { cursor: 'pointer' },
    onClick: (event, row) => {
      showStudentDisplay(false);
      setStudent(row);
      showStudentDisplay(true);
    },
  };

  const professorRowEvent = {
    style: { cursor: 'pointer' },
    onClick: (event, row) => {
      showProfessorDisplay(false);
      setProfessor(row);
      showProfessorDisplay(true);
    },
  };

  function schangeRadioStatus(event) {
    event.persist();
    setSError((prevState) => ({
      ...prevState,
      error: false,
      message: '',
    }));
    if (event.target.name === 'status') {
      setSStatus((prevState) => ({
        ...prevState,
        status: event.target.value,
      }));
    }
    if (event.target.name === 'invoice') {
      setInvoice((prevState) => ({
        ...prevState,
        invoice: event.target.value,
      }));
    }
  }

  function pchangeRadioStatus(event) {
    event.persist();
    setPError((prevState) => ({
      ...prevState,
      error: false,
      message: '',
    }));
    if (event.target.name === 'status') {
      setPStatus((prevState) => ({
        ...prevState,
        status: event.target.value,
      }));
    }
  }

  function downloadPDF() {
    window.location.href = `/payments/pdf/${student.id}?month=${month}`;
  }

  function comissions() {
    window.location.href = `/payments/comissions?month=${month}`;
  }

  function PDFs() {
    window.location.href = `/payments/pdfs?month=${month}`;
  }

  function studentsLefts() {
    const result = Object.entries(studentString).map(([key, value]) => `${key}: ${value}`).join(' |---|');
    navigator.clipboard.writeText(JSON.stringify(result)).then(alert('Copiado al portapapeles'));
  }

  function shandleSubmit() {
    if (!Sstatus || !invoice) {
      setSError((prevState) => ({
        ...prevState,
        error: true,
        message: 'Tienes que seleccionar los dos campos',
      }));
    } else {
      fetch(changeStudentStatusPath, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          StudentId: student.id,
          month: month2,
          status: Sstatus.status,
          invoice: invoice.invoice,
        }),
      })
        .then((response) => {
          if (response.status === 200) {
            setTimeout((window.location.href = `/payments?month=${month2}`), 1000);
          }
        })
        .catch((error) => {
          console.log(error);
          alert('Hubo un problema al registrar, por favor, intenta denuevo');
        });
    }
  }

  function phandleSubmit() {
    if (!Pstatus) {
      setPError((prevState) => ({
        ...prevState,
        error: true,
        message: 'Tienes que seleccionar el campo',
      }));
    } else {
      fetch(changeProfessorStatusPath, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ProfessorId: professor.id,
          month: month2,
          status: Pstatus.status,
        }),
      })
        .then((response) => {
          if (response.status === 200) {
            setTimeout((window.location.href = `/payments?month=${month2}`), 1000);
          }
        })
        .catch((error) => {
          console.log(error);
          alert('Hubo un problema al registrar, por favor, intenta denuevo');
        });
    }
  }

  function studentDisplayView() {
    return (
      <div className="col-md-6">
        <hr className="line" />
        <h4>{`Alumno:    ${student.name}`}</h4>
        <h4>{`Apoderado:    ${student.mother}`}</h4>
        <div className="row justify-content-center">
          <div className="col-md-4">
            <br />
            <h5>Estado del pago: </h5>
          </div>
          <div className="col-md-5">
            <form>
              <div className="control-group form-group">
                <input type="radio" className="big" name="status" id="status" value="OK" onChange={schangeRadioStatus} />
                <label htmlFor="status" className="radioLabel">  Listo</label>
                <br />
                <input type="radio" className="big" name="status" id="status" value="" onChange={schangeRadioStatus} />
                <label htmlFor="status" className="radioLabel">  Pendiente</label>
              </div>
            </form>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-4">
            <br />
            <h5>Envio de boleta: </h5>
            <input type="button" className="link-button" onClick={downloadPDF} value="Descargar boleta" />
          </div>
          <div className="col-md-5">
            <form>
              <div className="control-group form-group">
                <input type="radio" className="big" name="invoice" id="invoice" value="OK" onChange={schangeRadioStatus} />
                <label htmlFor="invoice" className="radioLabel">Listo</label>
                <br />
                <input type="radio" className="big" name="invoice" id="invoice" value="" onChange={schangeRadioStatus} />
                <label htmlFor="invoice" className="radioLabel">Pendiente</label>
              </div>
            </form>
          </div>
          <input className="button" type="button" onClick={shandleSubmit} value="Registrar" />
        </div>
        {Serror.error
          ? <h5 style={{ color: 'red' }}>{Serror.message}</h5>
          : <div />}
        <br />
        <br />
      </div>
    );
  }

  function professorDisplayView() {
    return (
      <div className="col-md-6">
        <hr className="line" />
        <h4>{`Profesor:    ${professor.name}`}</h4>
        <div className="row justify-content-center">
          <div className="col-md-4">
            <br />
            <h5>Estado del pago: </h5>
          </div>
          <div className="col-md-5">
            <form>
              <div className="control-group form-group">
                <input type="radio" className="big" name="status" id="status" value="OK" onChange={pchangeRadioStatus} />
                <label htmlFor="status" className="radioLabel">  Listo</label>
                <br />
                <input type="radio" className="big" name="status" id="status" value="" onChange={pchangeRadioStatus} />
                <label htmlFor="status" className="radioLabel">  Pendiente</label>
              </div>
            </form>
          </div>
        </div>
        <input className="button" type="button" onClick={phandleSubmit} value="Registrar" />
        {Perror.error
          ? <h5 style={{ color: 'red' }}>{Perror.message}</h5>
          : <div />}
        <br />
        <br />
      </div>
    );
  }

  function studentFilterDisplay() {
    if (studentFiltering == 'name') {
      return (
        <input id="student-name-filter" onChange={onStudentNameChange} placeholder="Alumno/a" />
      );
    } if (studentFiltering == 'mother') {
      return (
        <input id="student-mother-filter" onChange={onStudentMotherChange} placeholder="Apoderado/a" />
      );
    }
    return (
      <input id="student-amount-filter" onChange={onStudentAmountChange} placeholder="Monto" />
    );
  }

  // eslint-disable-next-line max-len
  const sortedProfessors = [].concat(professors).sort((a, b) => (a.name > b.name ? 1 : -1));
  // eslint-disable-next-line max-len
  const sortedStudents = [].concat(students).sort((a, b) => (a.admin.shortName > b.admin.shortName ? 1 : -1));

  function handleMonthChange(event) {
    event.persist();
    window.location.href = `/payments?month=${event.target.value}`;
  }

  function handleFilteringChange(event) {
    setShowStudentFilter(false);
    event.persist();
    setStudentFiltering(event.target.value);
    onStudentNameFilter('');
    onStudentMotherFilter('');
    onStudentAmountFilter('');
    setShowStudentFilter(true);
  }

  return (
    <div>
      <div className="row justify-content-center">
        <div className="col-md-5 text-center">
          <h1>Pagos</h1>
          <hr className="line" />
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="row justify-content-center">
            <div className="col-md-6 text-center">
              <p>{month2}</p>
              <h3>Indicadores</h3>
              <select className="form-select form-select-sm" name="month" id="month" onChange={handleMonthChange}>
                <option value="">Seleccione el mes</option>
                <option value="Marzo">Marzo</option>
                <option value="Abril">Abril</option>
                <option value="Mayo">Mayo</option>
                <option value="Junio">Junio</option>
                <option value="Julio">Julio</option>
                <option value="Agosto">Agosto</option>
                <option value="Septiembre">Septiembre</option>
                <option value="Octubre">Octubre</option>
                <option value="Noviembre">Noviembre</option>
                <option value="Diciembre">Diciembre</option>
              </select>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-4 offset-md-2">
              <h5>{`Clases totales:      ${totals.totalLessons}`}</h5>
              <h5>{`Horas totales:      ${totals.hours}`}</h5>
              <input type="button" className="link-button" onClick={PDFs} value="Descarga PDFs" />
              <input type="button" className="link-button" onClick={studentsLefts} value="Apoderados faltantes" />
            </div>
            <div className="col-md-6">
              <h6>{`Total:      ${totals.total}`}</h6>
              <h6>{`Pago profesores:      ${totals.pays}`}</h6>
              <h6>{`Pendiente de pago:      ${amountLeft} (${professorLeft})`}</h6>
              <h6>{`Por recaudar:      ${studentAmountLeft} (${studentLeft})`}</h6>
              <input type="button" className="link-button" onClick={comissions} value="Ver comisiones" />
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="row justify-content-center">
        <div className="col-md-5">
          <h3>Pagos a profesores</h3>
          <label htmlFor="professor-filter"> Filtrar:  </label>
          <input id="professor-filter" onChange={onProfessorChange} placeholder="Profesor/a" />
          <BootstrapTable
            keyField="id"
            data={sortedProfessors}
            columns={ProfessorsColumns}
            pagination={paginationFactory()}
            rowEvents={professorRowEvent}
            filter={filterFactory()}
          />
        </div>
        <div className="col-md-7">
          <h3>Cobros apoderados</h3>
          <div className="row justify-content-center">
            <label htmlFor="student-filter"> Filtrar por:  </label>
            <select className="form-select form-select-sm" name="month" id="month" onChange={handleFilteringChange}>
              <option value="name"> Alumno/a </option>
              <option value="mother"> Apoderado/a </option>
              <option value="amount"> Monto </option>
            </select>
            <div>
              {showStudentFilter
                ? studentFilterDisplay()
                : <div />}
            </div>
          </div>
          <BootstrapTable
            keyField="id"
            data={sortedStudents}
            columns={StudentsColumns}
            pagination={paginationFactory()}
            rowEvents={studentRowEvent}
            filter={filterFactory()}
          />
        </div>
      </div>
      <br />
      <div className="row justify-content-center">
        {studentDisplay
          ? studentDisplayView()
          : <div />}
      </div>
      <div className="row justify-content-center">
        {professorDisplay
          ? professorDisplayView()
          : <div />}
      </div>
    </div>
  );
}

export default hot(module)(Payments);
